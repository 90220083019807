import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: button;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    position: fixed;\n    bottom: 0;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  grid-area: cartSummary;\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: checkout;\n  padding: 3rem 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  border-bottom: 1px solid\n    ", ";\n  padding-bottom: 43px;\n  height: 85px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      \"navigation\"\n      \"checkout\"\n      \"button\";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 45px 0;\n  display: grid;\n\n  grid-template-columns: 8fr 4fr;\n  grid-template-rows: 85px auto auto;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"navigation cartSummary\"\n    \"checkout cartSummary\"\n    \"button cartSummary\";\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 10;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Loader = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2(), media.mediumScreen(_templateObject3()));
export var Navigation = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Checkout = styled.div(_templateObject5());
export var CartSummary = styled.div(_templateObject6(), media.mediumScreen(_templateObject7()));
export var Button = styled.div(_templateObject8());