import React from "react";
import * as S from "./styles";
export var ErrorMessage = function ErrorMessage(_ref) {
  var errors = _ref.errors;
  return errors && errors.length ? React.createElement(S.ErrorMessage, {
    className: "error_msg",
    "data-test": "errorMessage"
  }, errors.map(function (error, index) {
    return React.createElement(S.ErrorParagraph, {
      key: index,
      "data-test-id": index
    }, error.message);
  })) : null;
};