import React from "react";
import * as S from "./styles";

/**
 * The attribute
 */
export var Attribute = function Attribute(_ref) {
  var description = _ref.description,
      attributeValue = _ref.attributeValue;
  return React.createElement(S.Wrapper, null, React.createElement(S.Description, null, description), React.createElement("div", null, attributeValue));
};