import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { Address } from "@components/atoms";
import * as S from "./styles";

/**
 * Address tile option.
 */
var AddressTileOption = function AddressTileOption(_ref) {
  var id = _ref.id,
      inputName = _ref.inputName,
      address = _ref.address,
      onChange = _ref.onChange,
      checked = _ref.checked,
      props = _objectWithoutProperties(_ref, ["id", "inputName", "address", "onChange", "checked"]);

  return React.createElement(S.Label, {
    checked: !!checked,
    "data-test": "addressTile",
    "data-test-id": id
  }, React.createElement(Address, address), React.createElement(S.Input, _extends({}, props, {
    type: "radio",
    name: inputName,
    value: id,
    checked: checked,
    onChange: onChange
  })));
};

export { AddressTileOption };