import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect, useLocation } from "react-router-dom";
import { Button, Loader } from "@components/atoms";
import { CheckoutProgressBar } from "@components/molecules";
import { CartSummary } from "@components/organisms";
import { Checkout } from "@components/templates";
import { useCart, useCheckout } from "@saleor/sdk";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { checkoutMessages } from "@temp/intl";
import { CheckoutRouter } from "./CheckoutRouter";
import { CheckoutAddressSubpage, CheckoutPaymentSubpage, CheckoutReviewSubpage, CheckoutShippingSubpage } from "./subpages";

var prepareCartSummary = function prepareCartSummary(totalPrice, subtotalPrice, shippingTaxedPrice, promoTaxedPrice, items) {
  var products = items === null || items === void 0 ? void 0 : items.map(function (_ref) {
    var _variant$product, _variant$product$thum, _variant$product2, _variant$product2$thu, _variant$product3, _variant$product3$thu;

    var id = _ref.id,
        variant = _ref.variant,
        totalPrice = _ref.totalPrice,
        quantity = _ref.quantity;
    return {
      id: id || "",
      name: variant.name || "",
      price: {
        gross: {
          amount: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount) || 0,
          currency: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency) || ""
        },
        net: {
          amount: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.net.amount) || 0,
          currency: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.net.currency) || ""
        }
      },
      quantity: quantity,
      sku: variant.sku || "",
      thumbnail: {
        alt: ((_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : (_variant$product$thum = _variant$product.thumbnail) === null || _variant$product$thum === void 0 ? void 0 : _variant$product$thum.alt) || undefined,
        url: (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : (_variant$product2$thu = _variant$product2.thumbnail) === null || _variant$product2$thu === void 0 ? void 0 : _variant$product2$thu.url,
        url2x: (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : (_variant$product3$thu = _variant$product3.thumbnail2x) === null || _variant$product3$thu === void 0 ? void 0 : _variant$product3$thu.url
      }
    };
  });
  return React.createElement(CartSummary, {
    shipping: shippingTaxedPrice,
    subtotal: subtotalPrice,
    promoCode: promoTaxedPrice,
    total: totalPrice,
    products: products
  });
};

var getCheckoutProgress = function getCheckoutProgress(loaded, activeStepIndex, isShippingRequired) {
  var steps = isShippingRequired ? CHECKOUT_STEPS : CHECKOUT_STEPS.filter(function (_ref2) {
    var onlyIfShippingRequired = _ref2.onlyIfShippingRequired;
    return !onlyIfShippingRequired;
  });
  return loaded ? React.createElement(CheckoutProgressBar, {
    steps: steps,
    activeStep: activeStepIndex
  }) : null;
};

var getButton = function getButton(text, onClick) {
  if (text) {
    return React.createElement(Button, {
      testingContext: "checkoutPageNextStepButton",
      onClick: onClick,
      type: "submit"
    }, text);
  }

  return null;
};

var CheckoutPage = function CheckoutPage(_ref3) {
  var _checkout$shippingMet;

  _objectDestructuringEmpty(_ref3);

  var _useLocation = useLocation(),
      pathname = _useLocation.pathname;

  var _useCart = useCart(),
      cartLoaded = _useCart.loaded,
      shippingPrice = _useCart.shippingPrice,
      discount = _useCart.discount,
      subtotalPrice = _useCart.subtotalPrice,
      totalPrice = _useCart.totalPrice,
      items = _useCart.items;

  var _useCheckout = useCheckout(),
      checkoutLoaded = _useCheckout.loaded,
      checkout = _useCheckout.checkout,
      payment = _useCheckout.payment;

  var intl = useIntl();

  if (cartLoaded && (!items || !(items === null || items === void 0 ? void 0 : items.length))) {
    return React.createElement(Redirect, {
      to: "/cart/"
    });
  }

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      submitInProgress = _useState2[0],
      setSubmitInProgress = _useState2[1];

  var _useState3 = useState(payment === null || payment === void 0 ? void 0 : payment.gateway),
      _useState4 = _slicedToArray(_useState3, 2),
      selectedPaymentGateway = _useState4[0],
      setSelectedPaymentGateway = _useState4[1];

  var _useState5 = useState(payment === null || payment === void 0 ? void 0 : payment.token),
      _useState6 = _slicedToArray(_useState5, 2),
      selectedPaymentGatewayToken = _useState6[0],
      setSelectedPaymentGatewayToken = _useState6[1];

  useEffect(function () {
    setSelectedPaymentGateway(payment === null || payment === void 0 ? void 0 : payment.gateway);
  }, [payment === null || payment === void 0 ? void 0 : payment.gateway]);
  useEffect(function () {
    setSelectedPaymentGatewayToken(payment === null || payment === void 0 ? void 0 : payment.token);
  }, [payment === null || payment === void 0 ? void 0 : payment.token]);
  var matchingStepIndex = CHECKOUT_STEPS.findIndex(function (_ref4) {
    var link = _ref4.link;
    return link === pathname;
  });
  var activeStepIndex = matchingStepIndex !== -1 ? matchingStepIndex : 3;
  var activeStep = CHECKOUT_STEPS[activeStepIndex];
  var checkoutAddressSubpageRef = useRef(null);
  var checkoutShippingSubpageRef = useRef(null);
  var checkoutPaymentSubpageRef = useRef(null);
  var checkoutReviewSubpageRef = useRef(null);

  var handleNextStepClick = function handleNextStepClick() {
    var _checkoutAddressSubpa, _checkoutShippingSubp, _checkoutPaymentSubpa, _checkoutReviewSubpag;

    // Some magic above and below ensures that the activeStepIndex will always
    // be in 0-3 range

    /* eslint-disable default-case */
    switch (activeStepIndex) {
      case 0:
        if ((_checkoutAddressSubpa = checkoutAddressSubpageRef.current) === null || _checkoutAddressSubpa === void 0 ? void 0 : _checkoutAddressSubpa.submitAddress) {
          var _checkoutAddressSubpa2;

          (_checkoutAddressSubpa2 = checkoutAddressSubpageRef.current) === null || _checkoutAddressSubpa2 === void 0 ? void 0 : _checkoutAddressSubpa2.submitAddress();
        }

        break;

      case 1:
        if ((_checkoutShippingSubp = checkoutShippingSubpageRef.current) === null || _checkoutShippingSubp === void 0 ? void 0 : _checkoutShippingSubp.submitShipping) {
          var _checkoutShippingSubp2;

          (_checkoutShippingSubp2 = checkoutShippingSubpageRef.current) === null || _checkoutShippingSubp2 === void 0 ? void 0 : _checkoutShippingSubp2.submitShipping();
        }

        break;

      case 2:
        if ((_checkoutPaymentSubpa = checkoutPaymentSubpageRef.current) === null || _checkoutPaymentSubpa === void 0 ? void 0 : _checkoutPaymentSubpa.submitPayment) {
          var _checkoutPaymentSubpa2;

          (_checkoutPaymentSubpa2 = checkoutPaymentSubpageRef.current) === null || _checkoutPaymentSubpa2 === void 0 ? void 0 : _checkoutPaymentSubpa2.submitPayment();
        }

        break;

      case 3:
        if ((_checkoutReviewSubpag = checkoutReviewSubpageRef.current) === null || _checkoutReviewSubpag === void 0 ? void 0 : _checkoutReviewSubpag.complete) {
          var _checkoutReviewSubpag2;

          (_checkoutReviewSubpag2 = checkoutReviewSubpageRef.current) === null || _checkoutReviewSubpag2 === void 0 ? void 0 : _checkoutReviewSubpag2.complete();
        }

        break;
    }
  };

  var shippingTaxedPrice = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingMet = checkout.shippingMethod) === null || _checkout$shippingMet === void 0 ? void 0 : _checkout$shippingMet.id) && shippingPrice ? {
    gross: shippingPrice,
    net: shippingPrice
  } : null;
  var promoTaxedPrice = discount && {
    gross: discount,
    net: discount
  };
  var checkoutView = cartLoaded && checkoutLoaded ? React.createElement(CheckoutRouter, {
    items: items,
    checkout: checkout,
    payment: payment,
    renderAddress: function renderAddress(props) {
      return React.createElement(CheckoutAddressSubpage, _extends({
        ref: checkoutAddressSubpageRef,
        changeSubmitProgress: setSubmitInProgress
      }, props));
    },
    renderShipping: function renderShipping(props) {
      return React.createElement(CheckoutShippingSubpage, _extends({
        ref: checkoutShippingSubpageRef,
        changeSubmitProgress: setSubmitInProgress
      }, props));
    },
    renderPayment: function renderPayment(props) {
      return React.createElement(CheckoutPaymentSubpage, _extends({
        ref: checkoutPaymentSubpageRef,
        selectedPaymentGateway: selectedPaymentGateway,
        selectedPaymentGatewayToken: selectedPaymentGatewayToken,
        changeSubmitProgress: setSubmitInProgress,
        selectPaymentGateway: setSelectedPaymentGateway
      }, props));
    },
    renderReview: function renderReview(props) {
      return React.createElement(CheckoutReviewSubpage, _extends({
        ref: checkoutReviewSubpageRef,
        selectedPaymentGatewayToken: selectedPaymentGatewayToken,
        changeSubmitProgress: setSubmitInProgress
      }, props));
    }
  }) : React.createElement(Loader, null);
  var isShippingRequiredForProducts = items && items.some(function (_ref5) {
    var _variant$product4;

    var variant = _ref5.variant;
    return (_variant$product4 = variant.product) === null || _variant$product4 === void 0 ? void 0 : _variant$product4.productType.isShippingRequired;
  });
  var buttonText = activeStep.nextActionName;
  /* eslint-disable default-case */

  switch (activeStep.nextActionName) {
    case "Continue to Shipping":
      buttonText = intl.formatMessage(checkoutMessages.addressNextActionName);
      break;

    case "Continue to Payment":
      buttonText = intl.formatMessage(checkoutMessages.shippingNextActionName);
      break;

    case "Continue to Review":
      buttonText = intl.formatMessage(checkoutMessages.paymentNextActionName);
      break;

    case "Place order":
      buttonText = intl.formatMessage(checkoutMessages.reviewNextActionName);
      break;
  }

  return React.createElement(Checkout, {
    loading: submitInProgress,
    navigation: getCheckoutProgress(cartLoaded && checkoutLoaded, activeStepIndex, !!isShippingRequiredForProducts),
    cartSummary: prepareCartSummary(totalPrice, subtotalPrice, shippingTaxedPrice, promoTaxedPrice, items),
    checkout: checkoutView,
    button: getButton(buttonText.toUpperCase(), handleNextStepClick)
  });
};

export { CheckoutPage };