import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Tile } from "@components/atoms";
import * as S from "./styles";
import { useFunc, GET_ME_RECORD, GET_ME, RESET_POINT_Q } from "@temp/@next/hooks/useQuery";
export var PointRecordsTile = function PointRecordsTile() {
  var _sdata$data$data$shop;

  var _useFunc = useFunc(GET_ME_RECORD),
      _useFunc2 = _slicedToArray(_useFunc, 1),
      _useFunc2$ = _useFunc2[0],
      data = _useFunc2$.data,
      loading = _useFunc2$.loading,
      fetch = _useFunc2$.fetch,
      refetch = _useFunc2$.refetch;

  var _useFunc3 = useFunc(GET_ME),
      _useFunc4 = _slicedToArray(_useFunc3, 1),
      mdata = _useFunc4[0];

  var _useFunc5 = useFunc(RESET_POINT_Q),
      _useFunc6 = _slicedToArray(_useFunc5, 1),
      sdata = _useFunc6[0];

  React.useEffect(function () {
    refetch();
    mdata.refetch();
    sdata.refetch(); // if (data && !error) {
    //   setIsEditing(false);
    // }
  }, []); // console.log(data)
  // if (loading ) return <div></div>

  var records = data ? data.data.me.pointsRecords.edges : [];
  var points = mdata.data ? mdata.data.data.me.metadata.filter(function (item) {
    return item.key === "points";
  })[0].value : "---";
  var expire_point = mdata.data ? mdata.data.data.me.metadata.filter(function (item) {
    return item.key === "expire_points";
  })[0].value : "---";
  var expire_date = sdata.data ? (_sdata$data$data$shop = sdata.data.data.shop.metadata.filter(function (item) {
    return item.key === "next_date";
  })[0]) === null || _sdata$data$data$shop === void 0 ? void 0 : _sdata$data$data$shop.value : "---"; // console.log()

  expire_point = expire_point.includes("-") ? "0" : expire_point;
  return React.createElement(React.Fragment, null, React.createElement(S.TileWrapper, null, React.createElement(Tile, null, React.createElement("div", null, React.createElement("div", null, "Current TPoints : ", points), React.createElement("div", null, "Expiring TPoints : ", expire_point), React.createElement("div", null, "TPoints Expiring date : ", expire_date)))), React.createElement(S.TileWrapper, null, React.createElement(Tile, null, React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.PointRecordsTile.3736302570",
    defaultMessage: "TPOINT RECORDS"
  }), React.createElement("span", null, "Show last 25 records only")), React.createElement(S.Content, null, React.createElement("div", {
    className: "Grid_header"
  }, React.createElement("div", null, "Date"), React.createElement("div", null, "Transaction Type"), React.createElement("div", null, "Amount")), records.reverse().map(function (edge, idx) {
    var item = edge.node;
    var date = new Date(item.created);
    return React.createElement("div", {
      key: idx,
      className: "Grid_row"
    }, React.createElement("div", null, date.toLocaleDateString()), React.createElement("div", {
      style: {
        fontWeight: 800
      }
    }, item.recordType === "PURCHASE" ? "REDEEM" : item.amount > 0 ? "REWARDS" : "ADJUSTMENT"), React.createElement("div", null, item.recordType === "PURCHASE" ? -item.amount : item.amount));
  }))))));
};