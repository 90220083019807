import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { FormattedMessage } from "react-intl";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector";
import * as S from "./styles";

/**
 * Address form used in checkout.
 */
var CheckoutAddress = function CheckoutAddress(_ref) {
  var checkoutAddress = _ref.checkoutAddress,
      email = _ref.email,
      selectedUserAddressId = _ref.selectedUserAddressId,
      userAddresses = _ref.userAddresses,
      countries = _ref.countries,
      userId = _ref.userId,
      formRef = _ref.formRef,
      formId = _ref.formId,
      setShippingAddress = _ref.setShippingAddress,
      errors = _ref.errors,
      newAddressFormId = _ref.newAddressFormId;
  return React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), userAddresses ? React.createElement(AddressGridSelector, {
    formId: formId,
    formRef: formRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: errors,
    onSelect: function onSelect(address, id) {
      return setShippingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "shippingAddressForm",
    formId: formId,
    formRef: formRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: _objectSpread({}, checkoutAddress, {
      email: email
    }),
    handleSubmit: function handleSubmit(address) {
      return setShippingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: true,
    errors: errors
  }));
};

export { CheckoutAddress };