import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { CheckoutStep } from "@temp/core/config";
export var useCheckoutStepState = function useCheckoutStepState(items, checkout, payment) {
  var isShippingRequiredForProducts = items && items.some(function (_ref) {
    var _variant$product;

    var variant = _ref.variant;
    return (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.productType.isShippingRequired;
  });

  var getStep = function getStep() {
    if (!(checkout === null || checkout === void 0 ? void 0 : checkout.id) && items && isShippingRequiredForProducts) {
      return CheckoutStep.Address;
    }

    if (!(checkout === null || checkout === void 0 ? void 0 : checkout.id) && items) {
      return CheckoutStep.Payment;
    }

    var isShippingStep = !!(checkout === null || checkout === void 0 ? void 0 : checkout.shippingAddress) || !isShippingRequiredForProducts;
    var isPaymentStep = isShippingStep && !!(checkout === null || checkout === void 0 ? void 0 : checkout.shippingMethod) || !isShippingRequiredForProducts;
    var isReviewStep = isPaymentStep && !!(checkout === null || checkout === void 0 ? void 0 : checkout.billingAddress) && !!(payment === null || payment === void 0 ? void 0 : payment.id);

    if (isReviewStep) {
      return CheckoutStep.Review;
    }

    if (isPaymentStep) {
      return CheckoutStep.Payment;
    }

    if (isShippingStep) {
      return CheckoutStep.Shipping;
    }

    return CheckoutStep.Address;
  };

  var _useState = useState(getStep()),
      _useState2 = _slicedToArray(_useState, 2),
      step = _useState2[0],
      setStep = _useState2[1];

  useEffect(function () {
    var newStep = getStep();

    if (step !== newStep) {
      setStep(newStep);
    }
  }, [checkout, items, payment]);
  return step;
};