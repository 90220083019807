import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Formik } from "formik";
import React from "react";
import { Radio } from "@components/atoms";
import { useFunc, GET_ME } from "@hooks/useQuery";
import * as S from "./styles";
import { useCart } from "@saleor/sdk"; // import {queryFactory} from "@saleor/sdk/lib/react/useQuery";
// import { useSaleorClient } from "@saleor/sdk/lib/react/helpers"
// import { gql  } from "@apollo/client";

export var pointStatuses = [{
  token: "charged",
  label: "Charged"
}, {
  token: "fully-refunded",
  label: "Fully refunded"
}, {
  token: "not-charged",
  label: "Not charged"
}];
/**
 * Dummy payment gateway.
 */

var PointPaymentGateway = function PointPaymentGateway(_ref) {
  var processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId,
      initialStatus = _ref.initialStatus;

  var _useCart = useCart(),
      totalPrice = _useCart.totalPrice;

  var _useFunc = useFunc(GET_ME),
      _useFunc2 = _slicedToArray(_useFunc, 1),
      _useFunc2$ = _useFunc2[0],
      data = _useFunc2$.data,
      loading = _useFunc2$.loading; // const {data, loading} = useQuery(GET_ME)


  console.log(data);

  if (loading) {
    return React.createElement("div", null);
  }

  return React.createElement(React.Fragment, null, totalPrice ? "".concat(totalPrice.net.amount, " ").concat(totalPrice.net.currency) : "Invalid", React.createElement(Formik, {
    initialValues: {
      status: initialStatus || pointStatuses[0].token
    },
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      processPayment(values.status);
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "dummyPaymentGatewayForm"
    }, pointStatuses.map(function (_ref4) {
      var token = _ref4.token,
          label = _ref4.label;
      return React.createElement(S.Status, {
        key: token
      }, React.createElement(Radio, {
        key: token,
        type: "radio",
        name: "status",
        value: token,
        checked: values.status === token,
        onChange: handleChange
      }, React.createElement("span", null, label)));
    }));
  }));
};

export { PointPaymentGateway };