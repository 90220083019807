import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { InputSelect, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var AddressFormContent = function AddressFormContent(_ref) {
  var formRef = _ref.formRef,
      handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur,
      formId = _ref.formId,
      errors = _ref.errors,
      handleSubmit = _ref.handleSubmit,
      values = _ref.values,
      countriesOptions = _ref.countriesOptions,
      defaultValue = _ref.defaultValue,
      setFieldValue = _ref.setFieldValue,
      testingContext = _ref.testingContext,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail;
  var basicInputProps = useCallback(function () {
    return {
      onBlur: handleBlur,
      onChange: handleChange
    };
  }, [handleChange, handleBlur]);
  var intl = useIntl();
  var fieldErrors = {};

  if (errors) {
    errors.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;
      fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
        message: message
      }]) : [{
        message: message
      }];
    });
  }

  return React.createElement(S.AddressForm, {
    className: "testing",
    id: formId,
    ref: formRef,
    onSubmit: handleSubmit,
    "data-test": testingContext
  }, React.createElement(S.Wrapper, null, React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "firstName",
    label: intl.formatMessage(commonMessages.firstName),
    value: values.firstName,
    autoComplete: "given-name",
    errors: fieldErrors.firstName
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "lastName",
    label: intl.formatMessage(commonMessages.lastName),
    value: values.lastName,
    autoComplete: "family-name",
    errors: fieldErrors.lastName
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "companyName",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Company Name (Optional)",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.2950411968"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2950411968")),
    value: values.companyName,
    autoComplete: "organization",
    errors: fieldErrors.companyName
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "phone",
    label: intl.formatMessage(commonMessages.phone),
    value: values.phone,
    autoComplete: "tel",
    errors: fieldErrors.phone
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress1",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Address line 1",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.1363074570"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1363074570")),
    value: values.streetAddress1,
    autoComplete: "address-line1",
    errors: fieldErrors.streetAddress1
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress2",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Address line 2",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.3121963259"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.3121963259")),
    value: values.streetAddress2,
    autoComplete: "address-line2",
    errors: fieldErrors.streetAddress2
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "city",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "City",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.253031977"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.253031977")),
    value: values.city,
    autoComplete: "address-level2",
    errors: fieldErrors.city
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "postalCode",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "ZIP/Postal Code",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.2796540622"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2796540622")),
    value: values.postalCode,
    autoComplete: "postal-code",
    errors: fieldErrors.postalCode
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(InputSelect, {
    defaultValue: defaultValue,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Country",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.1139500589"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1139500589")),
    name: "country",
    options: countriesOptions,
    value: values.country && countriesOptions && countriesOptions.find(function (option) {
      return option.code === values.country.code;
    }),
    onChange: function onChange(value, name) {
      return setFieldValue(name, value);
    },
    optionLabelKey: "country",
    optionValueKey: "code",
    errors: fieldErrors.country,
    autoComplete: "country"
  }), React.createElement(TextField, _extends({
    name: "countryArea",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "State/province",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.404705399"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.404705399")),
    value: values.countryArea,
    autoComplete: "address-level1",
    errors: fieldErrors.countryArea
  }, basicInputProps()))), includeEmail && React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "email",
    label: intl.formatMessage(commonMessages.shortEmail),
    value: values.email,
    autoComplete: "email",
    errors: fieldErrors.email
  }, basicInputProps())))));
};