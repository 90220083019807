import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector";
import { DiscountForm } from "../DiscountForm";
import * as S from "./styles";
import { PointPaymentDirect } from "../PointPaymentGateway";
/**
 * Payment options used in checkout.
 */

var CheckoutPayment = function CheckoutPayment(_ref) {
  var gatewayErrors = _ref.gatewayErrors,
      billingErrors = _ref.billingErrors,
      promoCodeErrors = _ref.promoCodeErrors,
      selectedUserAddressId = _ref.selectedUserAddressId,
      userAddresses = _ref.userAddresses,
      _ref$billingAsShippin = _ref.billingAsShippingAddress,
      billingAsShippingAddress = _ref$billingAsShippin === void 0 ? true : _ref$billingAsShippin,
      checkoutBillingAddress = _ref.checkoutBillingAddress,
      countries = _ref.countries,
      billingFormRef = _ref.billingFormRef,
      billingFormId = _ref.billingFormId,
      paymentGateways = _ref.paymentGateways,
      setBillingAddress = _ref.setBillingAddress,
      billingAsShippingPossible = _ref.billingAsShippingPossible,
      setBillingAsShippingAddress = _ref.setBillingAsShippingAddress,
      promoCodeDiscountFormId = _ref.promoCodeDiscountFormId,
      promoCodeDiscountFormRef = _ref.promoCodeDiscountFormRef,
      promoCodeDiscount = _ref.promoCodeDiscount,
      addPromoCode = _ref.addPromoCode,
      removeVoucherCode = _ref.removeVoucherCode,
      submitUnchangedDiscount = _ref.submitUnchangedDiscount,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      gatewayFormRef = _ref.gatewayFormRef,
      gatewayFormId = _ref.gatewayFormId,
      userId = _ref.userId,
      newAddressFormId = _ref.newAddressFormId,
      processPayment = _ref.processPayment,
      onGatewayError = _ref.onGatewayError;

  var _useState = useState(!!(promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode)),
      _useState2 = _slicedToArray(_useState, 2),
      showPromoCodeForm = _useState2[0],
      setShowPromoCodeForm = _useState2[1];

  useEffect(function () {
    var isVoucherCode = !!(promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode);

    if (isVoucherCode) {
      setShowPromoCodeForm(isVoucherCode);
    }
  }, [promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode]);

  var handleChangeShowPromoCodeForm = function handleChangeShowPromoCodeForm() {
    setShowPromoCodeForm(!showPromoCodeForm);
  };

  var handleSubmitPromoCode = function handleSubmitPromoCode(discountForm) {
    var newPromoCode = discountForm === null || discountForm === void 0 ? void 0 : discountForm.promoCode;
    var savedPromoCode = promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode;

    if ((!newPromoCode || !showPromoCodeForm) && savedPromoCode) {
      removeVoucherCode(savedPromoCode);
    } else if (newPromoCode && newPromoCode !== savedPromoCode) {
      addPromoCode(newPromoCode);
    } else {
      submitUnchangedDiscount();
    }
  };

  var adresses = (userAddresses === null || userAddresses === void 0 ? void 0 : userAddresses.filter(filterNotEmptyArrayItems).map(function (address) {
    return {
      address: _objectSpread({}, address, {
        isDefaultBillingAddress: address.isDefaultBillingAddress || false,
        isDefaultShippingAddress: address.isDefaultShippingAddress || false,
        phone: address.phone || undefined
      }),
      id: (address === null || address === void 0 ? void 0 : address.id) || "",
      onSelect: function onSelect() {
        return null;
      }
    };
  })) || [];
  return React.createElement(S.Wrapper, null, React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.billingAddress)), billingAsShippingPossible && React.createElement(Checkbox, {
    "data-test": "checkoutPaymentBillingAsShippingCheckbox",
    name: "billing-same-as-shipping",
    checked: billingAsShippingAddress,
    onChange: function onChange() {
      return setBillingAsShippingAddress(!billingAsShippingAddress);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutPayment.CheckoutPayment.3912924864",
    defaultMessage: "Same as shipping address"
  })), !billingAsShippingAddress && React.createElement(React.Fragment, null, billingAsShippingPossible && React.createElement(S.Divider, null), userAddresses ? React.createElement(AddressGridSelector, {
    formId: billingFormId,
    formRef: billingFormRef,
    addresses: adresses,
    selectedAddressId: selectedUserAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: billingErrors,
    onSelect: function onSelect(address, id) {
      return setBillingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "billingAddressForm",
    formId: billingFormId,
    formRef: billingFormRef,
    countriesOptions: countries.filter(filterNotEmptyArrayItems),
    address: checkoutBillingAddress || undefined,
    handleSubmit: function handleSubmit(address) {
      return setBillingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: !billingAsShippingPossible,
    errors: billingErrors
  }))), React.createElement(S.Divider, null), React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.paymentMethod)), React.createElement("div", {
    className: "payment_voucher"
  }, React.createElement(Checkbox, {
    "data-test": "checkoutPaymentPromoCodeCheckbox",
    name: "payment-promo-code",
    checked: showPromoCodeForm,
    onChange: handleChangeShowPromoCodeForm
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutPayment.CheckoutPayment.3382401165",
    defaultMessage: "Do you have a gift card voucher or discount code?"
  })), showPromoCodeForm && React.createElement(S.DiscountField, null, React.createElement(DiscountForm, {
    discount: {
      promoCode: promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode
    },
    formId: promoCodeDiscountFormId,
    formRef: promoCodeDiscountFormRef,
    handleSubmit: handleSubmitPromoCode,
    errors: promoCodeErrors
  })), React.createElement(S.Divider, null)), React.createElement(PointPaymentDirect, {
    errors: gatewayErrors,
    paymentGateways: paymentGateways,
    processPayment: processPayment,
    selectPaymentGateway: selectPaymentGateway,
    formRef: gatewayFormRef,
    formId: gatewayFormId
  })));
};

export { CheckoutPayment };